<template>
  <div>
    <Layout>
      <PageHeader :title="title" :items="items" />
      <CourseForm />
    </Layout>
  </div>
</template>

<script>
import Vue from "vue";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header.vue";
import CourseForm from "@/components/course/CourseForm.vue";

export default Vue.extend({
  components: {
    Layout,
    PageHeader,
    CourseForm
  },
  data() {
    return {
      title: "Course Add",
      items: [
        {
          text: "Sucoding",
          href: "/"
        },
        {
          text: "Course",
          href: "/"
        },
        {
          text: "Course Add",
          active: true
        }
      ]
    };
  }
});
</script>
